// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Referrals from "../../blocks/Referrals/src/Referrals";
import Events2 from "../../blocks/Events2/src/Events2";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import CfEnquiriesAndCrm from "../../blocks/CfEnquiriesAndCrm/src/CfEnquiriesAndCrm";
import AdvancedSearch from "../../blocks/AdvancedSearch/src/AdvancedSearch";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import LiveChatSummary from "../../blocks/LiveChatSummary/src/LiveChatSummary";
import Download from "../../blocks/Download/src/Download";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import ContentManagement from "../../blocks/ContentManagement/src/ContentManagement";
import HamburgerMenu from "../../blocks/HamburgerMenu/src/HamburgerMenu";
import Documentation from "../../blocks/Documentation/src/Documentation";
import UploadMedia2 from "../../blocks/UploadMedia2/src/UploadMedia2";
import CfNewsMedia from "../../blocks/CfNewsMedia/src/CfNewsMedia";
import CertificationTracking from "../../blocks/CertificationTracking/src/CertificationTracking";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import EventRegistration from "../../blocks/EventRegistration/src/EventRegistration";
import AdminConsole3 from "../../blocks/AdminConsole3/src/AdminConsole3";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import TermsAndConditions from "../../blocks/TermsAndConditions/src/TermsAndConditions";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import AssessmentTest from "../../blocks/AssessmentTest/src/AssessmentTest";
import Chat9 from "../../blocks/Chat9/src/Chat9";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Analytics from "../../blocks/analytics/src/Analytics";
import AuditTrail from "../../blocks/AuditTrail/src/AuditTrail";
import ActivityFeed from "../../blocks/ActivityFeed/src/ActivityFeed";



const routeMap = {
Referrals:{
 component:Referrals,
path:"/Referrals"},
Events2:{
 component:Events2,
path:"/Events2"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
CfEnquiriesAndCrm:{
 component:CfEnquiriesAndCrm,
path:"/CfEnquiriesAndCrm"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
LiveChatSummary:{
 component:LiveChatSummary,
path:"/LiveChatSummary"},
Download:{
 component:Download,
path:"/Download"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
ContentManagement:{
 component:ContentManagement,
path:"/ContentManagement"},
HamburgerMenu:{
 component:HamburgerMenu,
path:"/HamburgerMenu"},
Documentation:{
 component:Documentation,
path:"/Documentation"},
UploadMedia2:{
 component:UploadMedia2,
path:"/UploadMedia2"},
CfNewsMedia:{
 component:CfNewsMedia,
path:"/CfNewsMedia"},
CertificationTracking:{
 component:CertificationTracking,
path:"/CertificationTracking"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
EventRegistration:{
 component:EventRegistration,
path:"/EventRegistration"},
AdminConsole3:{
 component:AdminConsole3,
path:"/AdminConsole3"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
TermsAndConditions:{
 component:TermsAndConditions,
path:"/TermsAndConditions"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
AssessmentTest:{
 component:AssessmentTest,
path:"/AssessmentTest"},
Chat9:{
 component:Chat9,
path:"/Chat9"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
AuditTrail:{
 component:AuditTrail,
path:"/AuditTrail"},
ActivityFeed:{
 component:ActivityFeed,
path:"/ActivityFeed"},

  Home: {
component:PhoneNumberInput,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
